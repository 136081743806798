<template>
  <v-dialog
    v-model="componente"
    transition="dialog-bottom-transition"
    fullscreen
    :overlay="false"
    scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3" card>
        <v-toolbar-title>
          <span v-if="componente._id">{{componente.descrizione}}</span>
          <span v-if="!componente._id">Nuovo componente</span>
          </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$router.push({ name: 'arrivi' })"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-card>
          <v-form ref="formComponente" v-model="validFormComponente">
            <v-container fluid grid-list-xl>
              <v-layout row wrap>
                <v-flex>
                  <v-select
                    label="Componente"
                    :items="anagComponenti"
                    item-text="descrizione"
                    item-value="_id"
                    v-if="!componente._id"
                    v-model="componente.componente_id" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    label="Codice*"
                    v-model="componente.codice"
                    :disabled="!!componente._id"
                    :rules="[$rules.maxlength(20)]" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-select
                    label="Cliente"
                    :items="clienti"
                    item-text="ragione_sociale"
                    item-value="_id"
                    v-model="componente.cliente_id" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-textarea
                    auto-grow
                    label="Descrizione"
                    v-model="componente.note"
                    :rules="[$rules.maxlength(4000)]" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                   <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="!validFormComponente || !componente.componente_id || !componente.codice"
                    @click.native="save()">
                    Salva
                  </v-btn>
                  <v-btn
                    class="ml-0"
                    color="error"
                    v-if="componente._id && (!movimenti || !movimenti.length)"
                    @click.native="deleteComponente()">
                    Elimina componente
                  </v-btn>
                  <v-btn v-if="componente._id && movimenti && movimenti.length" @click="stampa()">
                    Stampa
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-layout row wrap v-if="componente._id">
            <v-flex xs12>
              <h3 style="float:right;">
                Quantità disponibile {{componente.qta_in - componente.qta_out}}
              </h3>
            </v-flex>
          </v-layout>
          <v-list class="mt-2" two-line v-if="movimenti.length">
            <template v-for="(mov, index) in movimenti">
              <v-list-tile @click.stop="openArrDialog(mov)" :key="'tile-'+index">
                <v-list-tile-content>
                  <v-list-tile-title v-text="mov.data" />
                  <v-list-tile-title v-text="mov.descrizione" v-if="mov.descrizione" />
                  <v-list-tile-title v-text="mov.note" v-if="mov.note" />
                </v-list-tile-content>
                <v-list-tile-action v-if="mov._id">
                  <v-list-tile-action-text class="green--text" v-if="mov.qta > 0">
                    Qtà +{{mov.qta}}
                  </v-list-tile-action-text>
                  <v-list-tile-action-text class="red--text" v-else>
                    Qtà {{mov.qta}}
                  </v-list-tile-action-text>
                  <v-list-tile-action-text v-if="mov.qta > 0">
                    <v-chip v-if="mov.ordine_id">Avanzo di produzione</v-chip>
                    <v-chip v-else>Arrivo da fornitore</v-chip>
                    <v-icon @click.stop="deleteMov(mov)" color="error">delete</v-icon>
                  </v-list-tile-action-text>
                  <v-list-tile-action-text v-else>
                    <a v-if="mov.ingresso_id" @click="stampaCartello(mov.ingresso_id)">
                      Stampa cartello
                    </a>
                    <v-chip v-if="mov.ingresso_id">Spostamento in magazzino</v-chip>
                    <v-chip v-else>Ritiro avanzo</v-chip>
                    <v-icon @click.stop="deleteMov(mov)" v-if="!mov.inviata" color="error">delete</v-icon>
                  </v-list-tile-action-text>
                </v-list-tile-action>
                <v-list-tile-action v-else>
                  <v-list-tile-action-text class="red--text">
                    Qtà {{mov.qta}}
                  </v-list-tile-action-text>
                  <v-list-tile-action-text>
                    <v-chip>Prelievo per ordine</v-chip>
                  </v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="index" v-if="index < movimenti.length - 1" />
            </template>
          </v-list>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showArr"
      transition="dialog-bottom-transition"
      :overlay="false"
      width="500">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            <span v-if="arrivo._id">Modifica arrivo</span>
            <span v-else>Nuovo arrivo</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="showArr=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-card>
            <v-form ref="formArrivo" v-model="validFormArrivo">
              <v-container fluid grid-list-xl>
                <v-layout row wrap v-if="!arrivo._id">
                  <v-flex xs12>
                    <v-radio-group v-model="arrivo.tipo" @change="cambioTipo(arrivo)">
                      <v-radio label="Arrivo da fornitore" value="F" />
                      <v-radio label="Avanzo di produzione" value="A" v-if="ordini && ordini.length" />
                      <v-radio label="Ritiro avanzo" value="R" />
                      <v-radio label="Spostamento in magazzino" value="M" />
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <date-picker
                      label="Data*"
                      v-model="arrivo.data"
                      name="data"
                      :max="oggi" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Quantità (negativa se Ritiro avanzo o Spostamento in magazzino)*"
                      v-model="arrivo.qta"
                      :rules="[$rules.required, $rules.maxlength(8)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="arrivo.tipo==='F'">
                  <v-flex>
                    <v-select
                      label="Fornitore"
                      :items="fornitori"
                      item-text="nome"
                      item-value="_id"
                      v-model="arrivo.fornitore_id"
                      :rules="[$rules.required]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="arrivo.tipo==='M'">
                  <v-flex>
                    <v-select
                      label="Sede*"
                      :items="sedi"
                      v-model="arrivo.sede"
                      :rules="[$rules.required]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="arrivo.tipo==='M'">
                  <v-flex>
                    <v-select
                      label="Cliente"
                      :items="clienti"
                      item-text="ragione_sociale"
                      item-value="_id"
                      v-model="arrivo.cliente_id"
                      :rules="[$rules.required]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="arrivo.tipo==='A'">
                  <v-flex>
                    <v-select
                      label="Ordine"
                      :items="ordini"
                      item-text="desc"
                      item-value="_id"
                      v-model="arrivo.ordine_id" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea
                      auto-grow
                      label="Note"
                      v-model="arrivo.note"
                      :rules="[$rules.maxlength(4000)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-btn
                      class="ml-0"
                      color="primary"
                      :disabled="checkSave()"
                      @click.native="saveArr()">
                      Salva
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-menu left offset-x v-if="componente._id">
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openArrDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
    </v-menu>
    <modal-confirm ref="confirm" />
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import modalConfirm from '@/components/common/ModalConfirm'
import datePicker from '@/components/common/DatePicker'
import _clone from 'lodash/clone'

export default {
  data: () => ({
    componente: {},
    arrivo: {},
    movimenti: [],
    anagComponenti: [],
    fornitori: [],
    clienti: [],
    ordini: [],
    pages: 0,
    page: 1,
    showArr: false,
    paginate: 50,
    oggi: new Date().toISOString().split('T')[0],
    validFormComponente: false,
    validFormArrivo: false,
    sedi: [{
      value: 1,
      text: 'Cormano'
    }, {
      value: 2,
      text: 'Paderno'
    }]
  }),
  components: {
    datePicker,
    'modal-confirm': modalConfirm
  },
  methods: {
    checkSave () {
      return !this.validFormArrivo || !this.arrivo.qta || !this.arrivo.data ||
              ((this.arrivo.tipo === 'R' || this.arrivo.tipo === 'M') && this.arrivo.qta > 0) ||
              (this.arrivo.tipo !== 'R' && this.arrivo.tipo !== 'M' && this.arrivo.qta < 0)
    },
    save () {
      if (this.$refs.formComponente.validate()) {
        this.$plsqlSave('arrivo', this.componente)
          .then(response => {
            this.getFornitori()
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Componente salvato'
            })
            if (this.$route.params.id === 'add') {
              $EventBus.$emit('componente:added', response.data)
            }
          })
          .catch(err => {
            if (err.data.errCode === 'ORA-00001') {
              $EventBus.$emit('message', { type: 'error', text: 'Un componente con questo codice esiste già' })
            } else {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            }
          })
      }
    },
    deleteComponente () {
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'ATTENZIONE: se cancelli il componente verranno eliminati anche tutti i relativi arrivi.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.removeComp,
        modalObj: this.componente
      })
    },
    removeComp () {
      this.$plsqlDelete('arrivo', this.componente._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Componente eliminato'
          })
          this.$refs.confirm.hide()
          this.$router.push({ name: 'arrivi' })
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il componente perché usato in uno o più Ordini' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.$refs.confirm.hide()
        })
    },
    deleteMov (mov) {
      if (mov._id) {
        this.arrivo = mov
        this.$refs.confirm.show({
          modalTitle: 'Sicuro?',
          modalText: 'Sei certo di voler cancellare l\'arrivo del ' + mov.data + '?',
          modalButtonOk: 'Elimina',
          modalFunctionOk: this.remove,
          modalObj: mov
        })
      }
    },
    remove () {
      this.$plsqlMethod('arrivo', 'del_arrivo', { id: this.arrivo._id })
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Arrivo eliminato'
          })
          this.$refs.confirm.hide()
          this.getMovimenti()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-20001') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare l\'arrivo perché la quantità disponibile è insufficiente' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.$refs.confirm.hide()
        })
    },
    openArrDialog (arrivo) {
      if (arrivo) {
        if (!arrivo._id || arrivo.ingresso_id) return
        this.arrivo = _clone(arrivo)
        if (this.arrivo.fornitore_id) this.arrivo.tipo = 'F'
        else if (this.arrivo.ordine_id) this.arrivo.tipo = 'A'
        else this.arrivo.tipo = 'R'
        if (this.arrivo.fornitore_id) {
          this.$plsqlReadAll('fornitore', { fornitore_id: this.arrivo.fornitore_id, rows4page: 1000, cliente_id: this.componente.cliente_id })
            .then(response => {
              this.fornitori = response.data.items
              this.showArr = true
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        } else this.showArr = true
      } else {
        this.showArr = true
        this.arrivo = { tipo: 'F',
          arrivo_componente_id: this.componente._id,
          data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
        }
      }
    },
    saveArr () {
      if (this.$refs.formArrivo.validate()) {
        this.$plsqlMethod('arrivo', 'save_arrivo', this.arrivo)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Componente salvato'
            })
            this.showArr = false
            this.getMovimenti()
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    getMovimenti () {
      this.$plsqlRead('arrivo', this.componente._id || this.$route.params.id)
        .then(response => {
          this.componente = response.data
          this.$plsqlMethod('arrivo', 'get_movimenti', { id: this.componente._id })
            .then(response => {
              this.movimenti = response.data
              this.getFornitori()
              this.getClienti()
              this.getOrdini()
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore lettura componente'
          })
        })
    },
    cambioTipo (arrivo) {
      if (arrivo.tipo === 'F') { delete arrivo.ordine_id } else if (arrivo.tipo === 'A') { delete arrivo.fornitore_id } else {
        delete arrivo.ordine_id
        delete arrivo.fornitore_id
      }
    },
    getFornitori () {
      this.$plsqlReadAll('fornitore', {
        fornitore_id: this.componente.fornitore_id,
        rows4page: 1000,
        cliente_id: this.componente.cliente_id })
        .then(response => {
          this.fornitori = response.data.items
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    getOrdini () {
      this.$plsqlMethod('arrivo', 'get_ordini', { id: this.componente._id })
        .then(response => {
          this.ordini = response.data
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    stampa () {
      this.$http({
        method: 'post',
        url: '/pdf/componente',
        responseType: 'blob',
        data: { componente: this.componente, movimenti: this.movimenti, qta: this.componente.qta_in - this.componente.qta_out }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
      })
    },
    stampaCartello (id) {
      this.$plsqlMethod('arrivo', 'get_bancali', { ingresso: id })
        .then(response => {
          let toPrint = response.data
          this.$http({
            method: 'post',
            url: `/pdf/bancali`,
            responseType: 'blob',
            data: { bancali: toPrint }
          }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa dei bancali' })
          })
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    getClienti () {
      this.$plsqlReadAll('cliente', { page: 1, rows4page: 1000 })
        .then(response => {
          this.clienti = response.data.items
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
        })
    }
  },
  mounted () {
    if (this.$route.params.id && this.$route.params.id !== 'add') {
      this.getMovimenti()
    } else {
      this.componente = { }
      this.movimenti = []
      this.getFornitori()
      this.getClienti()
      this.$plsqlReadAll('componente', { page: 1, rows4page: 50000 })
        .then(response => {
          this.anagComponenti = response.data.items
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura anagrafica componenti' })
        })
    }

    $EventBus.$on('componente:added', (componente) => {
      this.componente = componente
      this.$router.push({ name: 'componente', params: { id: componente._id } })
    })
  }
}
</script>
